/* .post_container{
    min-width: 700px;
    height: 190px;
    margin-bottom: 25px;
    border: none;
    border-radius: 3px;
    color: black;
    border: 1px solid lightgrey;
    border-radius: 10px;
    display: flex;
    align-items: center;
} */

/* .column2{
    width: 300px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    min-height: 300px;
    padding: 10px;
} */

.post_content{
    padding: 5px 12px;
}

.post_content h3{
    margin-bottom: 8px;
}

.image_container{
    width: 280px;
    height: 190px;
}

.image_container img{
    width: 100%;
    height: 190px;
    border-bottom-left-radius: 9px;
    border-top-left-radius: 9px;
}

.read_more{
    background: transparent;
    background-color: black;
    color: #f8fafc;
    border: none;
    padding: 8px 12px;
    border-radius: 3px;
    text-align: left;
    border: 2px solid black;
}

.read_more:hover{
    background-color: white;
    color: black;
    border: 2px solid black;
}

.post_container h4{
    text-align: left;
    color: black;
    margin-bottom: 18px;
}

.post_details{
    margin-bottom: 5px;
}

.post_details p{
    padding-bottom: 2px;
}

@media screen and (max-width: 800px) {
    .post_container{
        max-width: 400px;
        height: auto;
        display: flex;
        flex-direction: column;
        /* align-items: center;
        justify-content: center; */
    }
}