*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

@font-face {
    font-family: poppins-regular;
    src: url('./Poppins/Poppins-Regular.ttf');
}

body{
    font-family: poppins-regular;
    /* background-image: url('./images/background_image.svg'); */
    background-repeat: no-repeat;
    background-size: cover;
    background-color: white;
}

.app{
    background-color: rgba(255, 255, 255, 0.85);
    padding: 0;
    padding-top: 20px;
    width: 100%;
    height: 100%;
}