.column2{
    width: 350px;
}

.post_image{
    max-width: 80px;
    height: 80px;
}

.post_image img{
    width: 100%;
}