.container{
    max-width: 90%;
    margin: 40px auto;
    /* padding: 0 20px; */
    height: 100%;
}

.sub_container{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.weather {
    width: 300px;
    height: auto;
    border: 1px solid lightgrey;
    border-radius: 8px;
    margin-top: 30px;
    padding: 12px;
}

.weather .heading{
    display: flex;
    align-items: center;
    justify-content: space-between  ;
}

#weather_text{
    font-size: 25px;
}

.coords{
    margin: 15px 0;
}

.coords p{
    line-height: 25px;
}

.post_container{
    max-width: 850px;
    margin: 30px auto;
    border: 1px solid lightgrey;
    border-radius: 8px;
    display: flex;
    gap: 20px;
    align-items: center;
    height: auto;
}

.post_content{
    max-width: 600px;
}

.image_container{
    width: 300px;
    height: 200px;
}

.image_container img{
    width: 300px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    height: 200px;
}

.line{
    background-color: lightgrey;
    width: 100%;
    height: 1px;
    margin: 5px 0;
}


.related{
    border: 1.1px solid lightgrey;
    padding: 18px;
    border-radius: 10px;
}

.related .heading h3{
    margin-bottom: 8px;
    letter-spacing: 5px;
}

.container h2{
    margin-bottom: 10px;
    letter-spacing: 5px;
}

.container p{
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
}

.post_content button {
    background: none;
    background-color: black;
    border-radius: 5px;
    border: none;
    color: white;
    padding: 8px;
    margin-top: 8px;
    border: 1px solid black;
}

.post_content button:hover{
    background: none;
    border: 1px solid black;
    color: black;
}

.post_details p {
    margin: 0;
}

.cloud {
    font-size: 75px;
}

.temp{
    font-size: 50px;
}

.column{
    display: flex;
}


@media screen and (max-width: 800px) {
    .container{
        padding: 0 12px;
    }
   .post_container{
    max-width: 290px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: auto;
   }
   .image_container{
    max-width: 100%;
   }
   .image_container img{
    width: 100%;
    height: 230px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    border-bottom-left-radius: 0;
   }

   .post_content{
    margin-top: 15px;
    padding: 10px;
   }

   .post_content h3{
    text-align: justify;
   }

   .sub_container{
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-around;
    margin: auto;
    padding: 0 25px;
   }

   .coords{
    display: none;
   }
}