.container{
    max-width: 900px;
    margin: 50px auto;
}

.container h2{
    text-align: center;
}

.container p{
    margin: 25px 0;
}

.container h3{
    text-align: center;
    margin: 55px 0;
}

.options{
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 400px;
    margin: 15px auto;
}

.option button{
    background-color: #4ae;
    padding: 13px 15px;
    border-radius: 6px;
    color: white;
    border: none;
}

.option div{
    width: 125px;
    text-align: center;
    margin-bottom: 12px;
}

.option_icon{
    font-size: 50px;
}

@media screen and (max-width: 600px) {
    .container{
        padding: 0 12px;
    }
    .heading h2{
        font-size: 18px;
    }

    .heading p{
        font-size: 13px;
        text-align: justify;
        line-height: 20px;
    }

    .options{
        display: flex;
        flex-direction: column;
    }

    .option{
        margin-bottom: 15px;
    }
}