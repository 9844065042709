.profile_container{
    width: 95%;
    margin: auto;
    padding-bottom: 25px;
}

.heading{
    text-align: center;
    margin-bottom: 20px;
}

.heading h2{
    margin-bottom: 1rem;
}

.form_container{
    max-width: 400px;
    margin: auto;
}

.form_control{
    width: 100%;
    margin: 20px auto;
}


input[type=text], select{
    width: 100%;
    padding: 12px;
    /* border: 2px solid #4ae; */
    background-color: rgba(255, 255, 255);
    border-radius: 5px;
    outline: none;
}


.submit_container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.submit_container button{
    background-color: #4ae;
    padding: 10px 15px;
    color: white;
    border-radius: 6px;
    border: none;
}

.arrow{
    color: white;
}

.error_text{
    color: tomato;
    font-size: 13.5px;
}

@media screen and (max-width: 600px) {
    .heading h2 {
        font-size: 19px;
    }

    .heading h3 {
        font-size: 15px;
    }

    .profile_container{
        padding: 0 15px;
    }

    
}





